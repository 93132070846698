import React from "react";
import styles from "./Footer.module.css";
import footerImage from "../../static/backgrounds/bg_footer.webp";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

function Footer() {
  let renderServices = () => (
    <div className={styles.widget}>
      <div className={styles.widget_title}>
        <p>Ürün ve Hizmetler</p>
      </div>
      <div className={styles.widget_content}>
        <ul className={styles.services_ul}>
          <Link
            rel="noreferrer"
            to="/web-tasarim"
            className={styles.services_link}
          >
            <li>
              <div className={styles.contact_content_area}>
                <p>Web Tasarım</p>
              </div>
              <Icon
                fontSize={18}
                color="white"
                className={styles.forwardIcon}
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>

          <div className={styles.subpage_style_container}>
            <Link
              rel="noreferrer"
              to="/dijital-reklamcilik"
              className={styles.services_link}
            >
              <li>
                <div className={styles.contact_content_area}>
                  <p>Dijital Reklamcılık</p>
                </div>

                <Icon
                  fontSize={18}
                  color="white"
                  className={styles.forwardIcon}
                  icon="akar-icons:arrow-forward"
                />
              </li>
            </Link>

            <Link
              rel="noreferrer"
              to="/dijital-reklamcilik/reklam-vermek"
              className={styles.services_link_subpage}
            >
              <li>
                <div className={styles.contact_content_area}>
                  <p>Google Reklamları</p>
                </div>

                <Icon
                  fontSize={18}
                  color="white"
                  className={styles.forwardIcon}
                  icon="akar-icons:arrow-forward"
                />
              </li>
            </Link>

            <Link
              rel="noreferrer"
              to="/dijital-reklamcilik/internet-reklami"
              className={styles.services_link_subpage}
            >
              <li>
                <div className={styles.contact_content_area}>
                  <p>İnternet Reklamları</p>
                </div>

                <Icon
                  fontSize={18}
                  color="white"
                  className={styles.forwardIcon}
                  icon="akar-icons:arrow-forward"
                />
              </li>
            </Link>
          </div>
          <div className={styles.subpage_style_container}>
            <Link
              rel="noreferrer"
              to="/logo-ve-kartvizit/logo-tasarim"
              className={styles.services_link}
            >
              <li>
                <div className={styles.contact_content_area}>
                  <p>Logo & Kartvizit</p>
                </div>
                <Icon
                  fontSize={18}
                  color="white"
                  className={styles.forwardIcon}
                  icon="akar-icons:arrow-forward"
                />
              </li>
            </Link>

            <Link
              rel="noreferrer"
              to="/logo-ve-kartvizit/logo-tasarim"
              className={styles.services_link_subpage}
            >
              <li>
                <div className={styles.contact_content_area}>
                  <p>Logo Tasarım</p>
                </div>

                <Icon
                  fontSize={18}
                  color="white"
                  className={styles.forwardIcon}
                  icon="akar-icons:arrow-forward"
                />
              </li>
            </Link>

            <Link
              rel="noreferrer"
              to="/logo-ve-kartvizit/kartvizit-tasarim"
              className={styles.services_link_subpage}
            >
              <li>
                <div className={styles.contact_content_area}>
                  <p>Kartvizit Tasarım</p>
                </div>

                <Icon
                  fontSize={18}
                  color="white"
                  className={styles.forwardIcon}
                  icon="akar-icons:arrow-forward"
                />
              </li>
            </Link>
          </div>
        </ul>
      </div>
    </div>
  );

  let renderUs = () => (
    <div className={styles.widget}>
      <div className={styles.widget_title}>
        <p>Biz</p>
      </div>

      <div className={styles.widget_content}>
        <ul className={styles.contact_ul}>
          <Link
            rel="noreferrer"
            to="/hakkimizda"
            className={styles.contact_link}
          >
            <li>
              <div className={styles.contact_content_area}>
                <p>Hakkımızda</p>
              </div>
              <Icon
                fontSize={18}
                color="white"
                className={styles.forwardIcon}
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>
          <Link
            rel="noreferrer"
            to="/bize-ulasin"
            className={styles.contact_link}
          >
            <li>
              <div className={styles.contact_content_area}>
                <p>Bize Ulaşın</p>
              </div>
              <Icon
                fontSize={18}
                color="white"
                className={styles.forwardIcon}
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>
          <Link
            rel="noreferrer"
            to={"/teklif-alin"}
            className={styles.contact_link}
          >
            <li>
              <div className={styles.contact_content_area}>
                <p>Teklif Alın</p>
              </div>
              <Icon
                fontSize={18}
                color="white"
                className={styles.forwardIcon}
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );

  let renderContact = () => (
    <div className={styles.widget}>
      <div className={styles.widget_title}>
        <p>İletişim</p>
      </div>

      <div className={styles.widget_content}>
        <ul className={styles.contact_ul}>
          <a
            onClick="return gtag_report_conversion()"
            rel="noreferrer"
            href="mailto:leventdijital34@gmail.com"
            className={styles.contact_link}
          >
            <li>
              <div className={styles.contact_content_area}>
                <Icon fontSize={17} icon="fontisto:email" />
                <p>Email : leventdijital34@gmail.com</p>
              </div>
              <Icon
                fontSize={18}
                color="white"
                className={styles.forwardIcon}
                icon="akar-icons:arrow-forward"
              />
            </li>
          </a>
          <a
            onClick="return gtag_report_conversion()"
            rel="noreferrer"
            target="_blank"
            href="https://wa.me/+905319652909?text=Merhaba,%20%C3%BCr%C3%BCn%20ve%20hizmetleriniz%20hakk%C4%B1nda%20bilgi%20almak%20istiyorum."
            className={styles.contact_link}
          >
            <li>
              <div className={styles.contact_content_area}>
                <Icon fontSize={15} icon="akar-icons:whatsapp-fill" />
                <p>Whatsapp : +90 531 965 29 09</p>
              </div>
              <Icon
                fontSize={18}
                color="white"
                className={styles.forwardIcon}
                icon="akar-icons:arrow-forward"
              />
            </li>
          </a>
          <a
            onClick="return gtag_report_conversion()"
            href="tel:+905319652909"
            rel="noreferrer"
            className={styles.contact_link}
          >
            <li>
              <div className={styles.contact_content_area}>
                <Icon fontSize={15} icon="bi:telephone" />
                <p>Telefon : +90 531 965 29 09</p>
              </div>
              <Icon
                fontSize={18}
                color="white"
                className={styles.forwardIcon}
                icon="akar-icons:arrow-forward"
              />
            </li>
          </a>
        </ul>
      </div>
    </div>
  );

  return (
    <div
      style={{
        background: `linear-gradient(to top, rgba(82, 0, 0, 0.950), rgba(82, 0, 0, 0.901)), url(${footerImage}) no-repeat top center`,
      }}
      className={styles.container}
      id="footer"
    >
      {/* Ürün ve Hizmetler */}
      {renderServices()}

      {/* Biz */}
      {renderUs()}

      {/* İletişim */}
      {renderContact()}

      <div
        style={{
          width: "100%",
          textAlign: "center",
          color: "#ccc",
          marginTop: ".5rem",
        }}
      >
        <p
          style={{
            fontSize: ".6rem !important",
          }}
        >
          Copyright © 2023 LeventDijital - Bütün Hakları Saklıdır
        </p>
      </div>
    </div>
  );
}

export default Footer;
