import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./config";
import { ref, child, push, update, getDatabase } from "firebase/database";

const app = initializeApp(firebaseConfig);

export function newContactForm(name, phone, subject, message) {
  const db = getDatabase(app);

  // A post entry.
  const postData = {
    name: name,
    phone: phone,
    subject: subject,
    message: message,
  };

  // Get a key for a new Post.
  const newPostKey = push(child(ref(db), "forms")).key;

  // Write the new post's data simultaneously in the posts list and the user's post list.
  const updates = {};
  updates["/posts/" + newPostKey] = postData;

  return update(ref(db), updates);
}
