import React from "react";
import styles from "./Navbar.module.css";
import logo from "../../static/logo-6.png";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { newContactForm } from "../../services/firebase/realtimeDatabase";

function Navbar() {
  const [menu, setMenu] = useState(false);
  const [submitFormSuccess, setSubmitFormSuccess] = useState(false);

  let handleMenu = () => {
    menu === false ? setMenu(true) : setMenu(false);
  };

  let submitForm = (e) => {
    e.preventDefault();
    newContactForm(
      e.target.name.value,
      e.target.phone.value,
      e.target.subject.value,
      e.target.message.value
    );
    setSubmitFormSuccess(true);
  };

  let menuContainer = () => (
    <div className={styles.menu}>
      <div className={styles.menu_wrapper}>
        <div className={styles.menu_title}>Hizmetler</div>
        <button onClick={handleMenu} className={styles.menu_close}>
          <Icon fontSize={30} color="black" icon="bi:x-lg" />
          <p>Kapat</p>
        </button>
        <ul>
          <Link onClick={handleMenu} to="/web-tasarim">
            <li>
              Web Tasarım
              <Icon
                fontSize={22}
                color="black"
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>

          <Link onClick={handleMenu} to="/dijital-reklamcilik">
            <li>
              Dijital Reklamcılık
              <Icon
                fontSize={22}
                color="black"
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>
          <Link
            className={styles.subpage}
            onClick={handleMenu}
            to="/dijital-reklamcilik/reklam-vermek"
          >
            <li style={{ paddingLeft: "25px !important" }}>
              Google Reklamları
              <Icon
                fontSize={22}
                color="black"
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>

          <Link
            className={styles.subpage}
            onClick={handleMenu}
            to="/dijital-reklamcilik/internet-reklami"
          >
            <li style={{ paddingLeft: "25px !important" }}>
              İnternet Reklamları
              <Icon
                fontSize={22}
                color="black"
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>

          <Link onClick={handleMenu} to="/logo-ve-kartvizit/logo-tasarim">
            <li>
              Logo & Kartvizit
              <Icon
                fontSize={22}
                color="black"
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>

          <Link
            className={styles.subpage}
            onClick={handleMenu}
            to="/logo-ve-kartvizit/logo-tasarim"
          >
            <li style={{ paddingLeft: "25px !important" }}>
              Logo Tasarım
              <Icon
                fontSize={22}
                color="black"
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>
          <Link
            className={styles.subpage}
            onClick={handleMenu}
            to="/logo-ve-kartvizit/kartvizit-tasarim"
          >
            <li style={{ paddingLeft: "25px !important" }}>
              Kartvizit Tasarım
              <Icon
                fontSize={22}
                color="black"
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>
        </ul>
        <div className={styles.menu_title}>Biz</div>
        <ul>
          <Link onClick={handleMenu} to="/hakkimizda">
            <li>
              Hakkımızda
              <Icon
                fontSize={22}
                color="black"
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>
          <Link onClick={handleMenu} to="/bize-ulasin">
            <li>
              Bize Ulaşın
              <Icon
                fontSize={22}
                color="black"
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>
          <Link onClick={handleMenu} to="/teklif-alin">
            <li style={{ color: "#cf0000", fontWeight: "600" }}>
              Teklif Alın
              <Icon
                fontSize={22}
                color="black"
                icon="akar-icons:arrow-forward"
              />
            </li>
          </Link>
        </ul>
        <div className={styles.menu_footer}>2022 © LeventDijital</div>
      </div>
      <div className={styles.menu_wrapper_form}>
        <div className={styles.menu_title}>Teklif Alın</div>
        <button onClick={handleMenu} className={styles.menu_close}>
          <Icon fontSize={30} color="black" icon="bi:x-lg" />
          <p>Kapat</p>
        </button>

        <form className={styles.contactForm} onSubmit={submitForm} action="#">
          <input type="text" name="name" placeholder="(*) Ad Soyad" required />
          <input
            type="tel"
            name="phone"
            required
            placeholder="(*) Telefon Numarası"
          />
          <input
            type="text"
            name="subject"
            required
            placeholder="(*) Teklif Almak İstediğiniz Konu "
          />
          <input type="text" name="message" placeholder="Mesajınız " />
          <button type="submit">Gönder</button>
          <br />
          {submitFormSuccess === true ? (
            <p className={styles.submitFormSuccess}>
              Form Başarıyla Gönderildi
            </p>
          ) : undefined}
        </form>

        <div className={styles.menu_footer}>2022 © LeventDijital</div>
      </div>
    </div>
  );

  return (
    <div className={styles.navbar}>
      <div className={styles.navbar_wrapper}>
        <div className={styles.navbar_logo}>
          <Link to="/">
            <img src={logo}  alt="LeventDijital Logo" />
          </Link>
        </div>
        <button onClick={handleMenu} className={styles.navbar_menu}>
          <Icon fontSize={24} color="black" icon="bytesize:menu" />
          <p>Menü</p>
        </button>
      </div>
      {menu ? menuContainer() : undefined}
    </div>
  );
}

export default Navbar;
