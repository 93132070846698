import React from "react";
import styles from "./BizeUlasin.module.css";
import { useEffect } from "react";
import PageHeader from "../../../pageHeader/PageHeader";
import headerImage from "../../../../static/backgrounds/bg_4.webp";
import { Icon } from "@iconify/react";

function BizeUlasin() {
  useEffect(() => {
    document.title = "Bize Ulaşın | Levent Dijital";
    return () => {};
  }, []);

  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <PageHeader title="Bize Ulaşın" image={headerImage} />
      <div className={styles.container}>
        <a
          onClick="return gtag_report_conversion()"
          href="tel:+90 531 965 29 09"
          className={styles.contact_method}
        >
          <div className={styles.logo_area}>
            <Icon fontSize={24} icon="bi:telephone" />
          </div>
          <h4 className={styles.contact_title}>Telefon</h4>
          <h4 className={styles.contact_info}>
            <p>+90 531 965 29 09</p>
          </h4>
        </a>
        <a
          onClick="return gtag_report_conversion()"
          href="mailto:leventdijital34@gmail.com"
          className={styles.contact_method}
        >
          <div className={styles.logo_area}>
            <Icon fontSize={24} icon="fontisto:email" />
          </div>
          <h4 className={styles.contact_title}>Email</h4>
          <h4 className={styles.contact_info}>
            <p>leventdijital34@gmail.com</p>
          </h4>
        </a>
        <a
          onClick="return gtag_report_conversion()"
          target="_blank"
          rel="noreferrer"
          href="https://wa.me/+905319652909?text=Merhaba,%20%C3%BCr%C3%BCn%20ve%20hizmetleriniz%20hakk%C4%B1nda%20bilgi%20almak%20istiyorum."
          className={styles.contact_method}
        >
          <div className={styles.logo_area}>
            <Icon fontSize={24} icon="akar-icons:whatsapp-fill" />
          </div>
          <h4 className={styles.contact_title}>Whatsapp Destek</h4>
          <h4 className={styles.contact_info}>
            <p>+90 531 965 29 09 </p>
          </h4>
        </a>
      </div>
    </div>
  );
}

export default BizeUlasin;
