import React from "react";
import { useEffect } from "react";
import PageHeader from "../../../../pageHeader/PageHeader";
import headerImage from "../../../../../static/backgrounds/bg_8.webp";
import styles from "./KartvizitTasarim.module.css";
import { Link } from "react-router-dom";

function KartvizitTasarim() {
  useEffect(() => {
    document.title = "Kartvizit Tasarım | Levent Dijital";
    return () => {};
  }, []);

  let renderQuestions = () => (
    <div className={styles.container_1}>
      <div className={styles.container_question}>
        <h2>
          <strong>Kartvizit Tasarımı</strong>
        </h2>
        <p>
          İyi bir kartvizit tasarımı, markanızı müşterinin aklında farklı bir
          yere konumlandırabilir.
          <br />
          <br />
          Tasarım çizgileri, markanızın karakterini oluşturur. Müşterileriniz
          ile daha etkili etkileşim kurmanızı sağlar.
        </p>
      </div>
    </div>
  );

  let renderContactBar = () => (
    <div className={styles.contactBar_container}>
      <ul>
        <li>
          <a href="#footer">İletişim</a>
        </li>
        <li>
          <Link to="/logo-ve-kartvizit/logo-tasarim">Logo Tasarım</Link>
        </li>
        <li>
          <Link to="/teklif-alin">Fiyat Alın</Link>
        </li>
      </ul>
    </div>
  );

  return (
    <div className={styles.container}>
      <PageHeader title="Kartvizit Tasarım" image={headerImage} />

      {renderContactBar()}
      {renderQuestions()}
    </div>
  );
}

export default KartvizitTasarim;
