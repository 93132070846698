import React from "react";
import { useEffect } from "react";
import PageHeader from "../../../../pageHeader/PageHeader";
import headerImage from "../../../../../static/backgrounds/bg_9.webp";
import styles from "./InternetReklamlari.module.css";
import { Link } from "react-router-dom";

function InternetReklamlari() {
  useEffect(() => {
    document.title = "İnternet Reklamı | Levent Dijital";
    document.getElementsByTagName("META")[2].name = "description";
    document.getElementsByTagName("META")[2].content =
      "İnternet Reklamı : internet'te ürün ve hizmetlerinizi arayan müşterilere reklamlar ile ulaşmak çok kolay. Reklam uzmanları, reklam kampanyalarınızı yönetir ve üst sıralarda yer almanız için optimizasyon yaparlar.";
    return () => {};
  }, []);

  let renderQuestions = () => (
    <div className={styles.container_1}>
      <div className={styles.container_question}>
        <h1>
          <strong>İnternet Reklamı</strong>
        </h1>
        <p>
          Her gün, binlerce insan sunduğunuz ürün ve hizmetleri arıyor. <br />
          <br />
          Internet'te reklam vermek müşterilere ulaşmanız için iyi bir yöntem.
        </p>
      </div>

      <div className={styles.container_question}>
        <h2>
          <strong>İnternet Reklamı Nerede Yayınlanır ?</strong>
        </h2>
        <p>
          Hizmet ve ürünlerinizin <strong>internet reklamı</strong> Google &
          Youtube & Internet Siteleri ve Sosyal Medya'da yayınlanabilir.
          İhtiyaçlarınıza göre en çok satış yapacağınız reklam alanlarını
          belirleriz ve bütçenize uygun şekilde reklamlarınızı yayınlarız.
          <br /> <br />
          Potansiyel müşterilere markanızı tanıtır, marka bilinirliği yaratırız.
          <br />
          <br />
          İnternet reklamları, müşterileriniz ile bağlantı kurmak için yüksek
          verimli bir yöntem. Google Arama Sonuçların'da yayınlanan reklamlar,
          potansiyel müşterilerin sizinle iletişime geçmesi için çok önemlidir.
          <br />
          <br /> İnsanlar internette gördükleri her ürünü hemen
          almayabiliyorlar. Fakat remarketing yöntemleri ile websitenize uğrayan
          bir müşteriye reklamlarınızı daha çok gösteririz, ve potansiyel bir
          müşterinin, ziyaret ettiği ürünü daha fazla görmesini sağlayarak
          dönüşüm elde ederiz.
        </p>
      </div>
      <div className={styles.container_question}>
        <h2>
          <strong>Biz Ne Yapıyoruz ?</strong>
        </h2>
        <p>
          Levent Dijital olarak reklamlarınızın internette yayınlanacağı en
          uygun sosyal kanallarını seçeriz. <br />
          <br /> İşletmenizin sunduğu hizmet ve ürünler için reklam kampanyaları
          hazırlar, reklamlarınızı oluşturur, hedef kitlenizi belirler ve
          kampanyalarınızı internette yayınlarız. <br />
          <br />
          Hazırladığımız kampanyalardan elde ettiğimiz dönüşüm, analiz ve
          verileri müşterilerimiz ile paylaşırız.
          <br /> <br />
          <Link className={styles.reach_us_link} to={"/bize-ulasin"}>
            İletişim için tıklayın.
          </Link>
          <br />
          <br />
          <Link className={styles.reach_us_link} to={"/teklif-alin"}>
            Fiyat teklifi almak için tıklayın.
          </Link>
        </p>
      </div>
    </div>
  );

  let renderContactBar = () => (
    <div className={styles.contactBar_container}>
      <ul>
        <li>
          <a href="#footer">İletişim</a>
        </li>
        <li>
          <Link to="/teklif-alin">Fiyat Alın</Link>
        </li>
      </ul>
    </div>
  );

  let renderGoogleAdsBar = () => (
    <div className={styles.contactBar_container}>
      <ul>
        <li>
          <Link to="/dijital-reklamcilik/reklam-vermek">
            Google'da Reklam Vermek
          </Link>
        </li>
      </ul>
    </div>
  );

  return (
    <div className={styles.container}>
      <PageHeader title="İnternet Reklamları" image={headerImage} />

      {renderContactBar()}
      {renderGoogleAdsBar()}
      {renderQuestions()}
    </div>
  );
}

export default InternetReklamlari;
