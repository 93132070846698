export const firebaseConfig = {
  apiKey: "AIzaSyD8lT98Re0pu4Hakr3AOON5WTTeKr0z-hE",
  authDomain: "reklamverelim-8ceb3.firebaseapp.com",
  databaseURL:
    "https://reklamverelim-8ceb3-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "reklamverelim-8ceb3",
  storageBucket: "reklamverelim-8ceb3.appspot.com",
  messagingSenderId: "1084926158959",
  appId: "1:1084926158959:web:271fd19f76ce3abf63c59a",
};
