import { Icon } from "@iconify/react";
import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import PageHeader from "../../../pageHeader/PageHeader";
import styles from "./Anasayfa.module.css";
import headerImage from "../../../../static/backgrounds/bg_1.webp";

function Anasayfa() {
  useEffect(() => {
    document.title = "Levent Dijital | Yeni Dünyaya Adım At";

    return () => {};
  }, []);

  let serviceCart = () => (
    <section className={styles.services_section}>
      <div className={styles.services}>
        <h2 className={styles.services_title}>Hizmet ve Ürünler</h2>
      </div>
      <div className={styles.services_container}>
        <Link to="/web-tasarim" className={styles.services_service}>
          <div className={styles.services_service_header}>
            <div className={styles.services_service_logo_area}>
              <Icon fontSize={24} icon="gg:website" />
            </div>
            <h2 className={styles.services_service_title}>Web Tasarım</h2>
            <div className={styles.services_service_details}>Detaylar</div>
          </div>
        </Link>
        {/* <Link to="/mobil-uygulama" className={styles.services_service}>
          <div className={styles.services_service_header}>
            <div className={styles.services_service_logo_area}>
              <Icon fontSize={24} icon="ph:app-store-logo-bold" />
              <Icon fontSize={24} icon="ph:google-play-logo-light" />
            </div>
            <h3 className={styles.services_service_title}>Mobil Uygulama</h3>
            <div className={styles.services_service_details}>Detaylar</div>
          </div>
        </Link> */}

        <Link to="/dijital-reklamcilik" className={styles.services_service}>
          <div className={styles.services_service_header}>
            <div className={styles.services_service_logo_area}>
              <Icon fontSize={24} icon="cib:google-ads" />
              <Icon fontSize={24} icon="akar-icons:instagram-fill" />
            </div>
            <h2 className={styles.services_service_title}>
              Dijital Reklamcılık
            </h2>
            <div className={styles.services_service_details}>Detaylar</div>
          </div>
        </Link>
        {/* <Link to="/e-ticaret-yazilim" className={styles.services_service}>
          <div className={styles.services_service_header}>
            <div className={styles.services_service_logo_area}>
              <Icon fontSize={24} icon="ep:shopping-trolley" />
            </div>
            <h3 className={styles.services_service_title}>E-Ticaret Yazılım</h3>
          </div>
          <div className={styles.services_service_details}>Detaylar</div>
        </Link>
        <Link to="/ozel-yazilim" className={styles.services_service}>
          <div className={styles.services_service_header}>
            <div className={styles.services_service_logo_area}>
              <Icon fontSize={24} icon="carbon:container-software" />
            </div>
            <h3 className={styles.services_service_title}>Özel Yazılım</h3>
            <div className={styles.services_service_details}>Detaylar</div>
          </div>
        </Link> */}
        <Link to="/logo-ve-kartvizit/logo-tasarim" className={styles.services_service}>
          <div className={styles.services_service_header}>
            <div className={styles.services_service_logo_area}>
              <Icon fontSize={24} icon="fluent:design-ideas-20-regular" />
            </div>
            <h2 className={styles.services_service_title}>Logo & Kartvizit</h2>
            <div className={styles.services_service_details}>Detaylar</div>
          </div>
        </Link>
      </div>
    </section>
  );

  return (
    <div className={styles.container}>
      <PageHeader
        title="Reklam ve Yazılım"
        image={headerImage}
      ></PageHeader>
      {serviceCart()}
    </div>
  );
}

export default Anasayfa;
