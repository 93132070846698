import React from "react";
import { useEffect } from "react";
import PageHeader from "../../../pageHeader/PageHeader";
import headerImage from "../../../../static/backgrounds/bg_5.webp";
import styles from "./Hakkimizda.module.css";

function Hakkimizda() {
  useEffect(() => {
    document.title = "Hakkımızda | Levent Dijital";
    return () => {};
  }, []);
  return (
    <div className={styles.container}>
      <PageHeader title="Hakkımızda" image={headerImage} />
      <div className={styles.about_us}>
        <p>
          Levent Dijital, dijital müşteri deneyimi odaklı ürün portföyü ile
          müşteri deneyimini esas alan markalaştırma ve dijital dönüşüm sunan
          bir yazılım şirketidir.
          <br /> <br />
          Müşteri deneyimini önceliklendiren kurumların yazılım ve tanıtım
          ihtiyaçlarını karşılamakta ve en iyi hizmeti sunmaya devam etmektedir.
          Şirketlerin dijital dönüşüm yolculuklarında iş ortağı olmayı
          sürdürmektedir. <br /> <br />
          Uzman ekibi ile pek çok farklı sektöre hizmet vermektedir.
          <br /> <br />
          Birçok başarılı projeye imza atmış bir yazılım şirketi olarak öne
          çıkan ürün ve hizmetleriyle uluslararası standartlarda faaliyet
          gösterir. <br />
        </p>
      </div>
    </div>
  );
}

export default Hakkimizda;
