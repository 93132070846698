import React from "react";
import { useEffect } from "react";
import PageHeader from "../../../../pageHeader/PageHeader";
import headerImage from "../../../../../static/backgrounds/bg_8.webp";
import styles from "./GoogleReklamlari.module.css";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

function GoogleReklamlari() {
  useEffect(() => {
    document.title = "İnternet Reklamı ve Reklam Vermek | Levent Dijital";
    document.getElementsByTagName("META")[2].name = "description";
    document.getElementsByTagName("META")[2].content =
      "Reklam Vermek ve İnternet Reklamı için çözümler sunan Levent Dijital, Google Reklamları ile web sitenizi tanıtabilir, potansiyel müşterilerinizi hedefleyebilirsiniz. Reklam kampanyalarınızı profesyonel reklam uzmanları yönetir ve optimizasyon teknikleriyle üst sıralarda yer almanızı sağlar. İşletmenizin görünürlüğünü artırın ve marka bilinirliğini yükseltin.";
    return () => {};
  }, []);

  let renderQuestions = () => (
    <div className={styles.container_1}>
      <div className={styles.container_question}>
        <h2 className={styles.question}>
          <strong>İnternet Reklamı ve Reklam Vermek</strong>
        </h2>
        <p>
          İnternet reklamcılığı, ürün ve hizmetlerinizi arayan müşterilere
          kolayca ulaşmanın en etkili yollarından biridir. <br /> Google
          Reklamları sayesinde web sitenizi tanıtabilir, potansiyel
          müşterilerinizi hedefleyebilirsiniz. Reklam kampanyalarınızı
          profesyonel reklam uzmanları yönetir ve optimizasyon teknikleriyle üst
          sıralarda yer almanızı sağlar.
        </p>
      </div>

      <div className={styles.container_question}>
        <h2>
          <strong>Google Reklamları ile Ürün ve Hizmetlerinizi Tanıtın</strong>
        </h2>
        <p>
          Google Reklamları, ürün ve hizmetlerinizi internet kullanıcılarına
          göstermek için güçlü bir platform sunar. Potansiyel müşterilerinizin
          Google'da yaptıkları aramalarla ilgili anahtar kelimeleri
          belirleyerek, reklamlarınızın doğru hedef kitleye ulaşmasını
          sağlayabilirsiniz. Böylece işletmenizin görünürlüğünü artırabilir,
          marka bilinirliğini yükseltebilirsiniz.
        </p>
      </div>

      <div className={styles.container_question}>
        <h2><strong>Reklam Kampanyalarınızı Profesyoneller Yönetsin</strong></h2>
        <p>
          Reklam kampanyalarınızı etkin bir şekilde yönetmek, başarıya ulaşmanız
          için önemlidir. Reklam uzmanları, Google Reklamları'nı en iyi şekilde
          kullanarak kampanyalarınızı optimize eder. Anahtar kelimeleri doğru
          seçer, reklam bütçenizi verimli kullanmanızı sağlar ve geri dönüşüm
          oranınızı artırır. Böylece rekabetin yoğun olduğu dijital pazarlama
          dünyasında öne çıkmanızı sağlar.
        </p>
      </div>
      <div className={styles.container_question}>
        <h2><strong>Optimizasyon ile Üst Sıralara Yükselin</strong></h2>
        <p>
          Google'da üst sıralarda yer almanız, potansiyel müşterilerin sizi
          bulmasını kolaylaştırır. Reklam uzmanları, reklam kampanyalarınızı
          optimize ederek arama sonuçlarında üst sıralara çıkmanızı sağlar.
          Doğru anahtar kelimeleri hedefleyerek, rekabetçi pazarlarda dahi
          görünürlüğünüzü artırır. Böylece daha fazla tıklama ve dönüşüm elde
          edebilirsiniz.
        </p>
      </div>
      <div className={styles.container_question}>
        <h2><strong>İnternet Reklamı ve Reklam Vermek</strong></h2>
        <p>
          İnternet reklamcılığı, ürün ve hizmetlerinizi arayan müşterilere
          kolayca ulaşmanın en etkili yollarından biridir. <br /> Google
          Reklamları sayesinde web sitenizi tanıtabilir, potansiyel
          müşterilerinizi hedefleyebilirsiniz. Reklam kampanyalarınızı
          profesyonel reklam uzmanları yönetir ve optimizasyon teknikleriyle üst
          sıralarda yer almanızı sağlar.
        </p>
      </div>


      <div className={styles.container_question}>
        <h2>
          <strong>Reklam Vermek için İletişime Geçin</strong>
        </h2>
        <p>
          Levent Dijital olarak Google'da reklam vermeniz için danışmanlık
          sağlarız. 
          <br /> <br />
          <Link className={styles.reach_us_link} to={"/bize-ulasin"}>
            İletişim için tıklayın.
          </Link>
          <br />
          <br />
          <Link className={styles.reach_us_link} to={"/teklif-alin"}>
            Fiyat Teklifi Formu için tıklayın.
          </Link>
        </p>
      </div>
    </div>
  );

  let renderPricePackages = () => (
    <div id="paketler" className={styles.packages_container}>
      <h1 className={styles.packages_title}>
        <strong>Paketler ve Fiyatlar</strong>
      </h1>
      <ul className={styles.packages}>
        <li className={styles.package}>
          <h3 className={styles.package_title}><strong>Standart</strong> / <small>Aylık</small></h3>
          <div className={styles.package_content}>
            <ul>
              <li>3 Günde Kurulum</li>
              <li>Haftalık Optimizasyon</li>
              <li>Remarketing </li>
              <li>Mobil Uygulama Desteği</li>
              <li>Search / Display / Video </li>
              <li>Aylık Raporlama</li>
              <li>İçerik ve Reklam Yönetimi</li>
              <li>Google Business Entegrasyonu</li>
            </ul>
          </div>
          <div className={styles.package_price}>
            <p>1.500,00 ₺</p>
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://wa.me/+905319652909?text=Merhaba, Standart Reklam Paketi hakkında bilgi almak istiyorum."
            className={styles.package_contact}
          >
            <Icon fontSize={16} color="green" icon="akar-icons:whatsapp-fill" />
            <p>Whatsapp Destek</p>
          </a>
        </li>
      </ul>
    </div>
  );

  let renderContactBar = () => (
    <div className={styles.contactBar_container}>
      <ul>
        <li>
          <a href="#footer">İletişim</a>
        </li>
        <li>
          <Link to="/teklif-alin">Fiyat Alın</Link>
        </li>
      </ul>
    </div>
  );

  return (
    <div className={styles.container}>
      <PageHeader title="İnternet Reklamı ve Reklam Vermek" image={headerImage} />
      {renderContactBar()}
      {renderQuestions()}
      {/* {renderPricePackages()} */}

    </div>
  );
}

export default GoogleReklamlari;
