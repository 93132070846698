import React  from 'react';
import { Route, Routes } from "react-router-dom";
import Navbar from "../../navBar/Navbar";
import Anasayfa from "../pages/anasayfa/Anasayfa";
import WebTasarim from "../pages/web-tasarim/WebTasarim";
import LogoVeKartvizit from "../pages/logo-ve-kartvizit/LogoVeKartvizit";
import DijitalReklamcilik from "../pages/dijital-reklamcilik/DijitalReklamcilik";
import BizeUlasin from "../pages/bize-ulasin/BizeUlasin";
import Hakkimizda from "../pages/hakkimizda/Hakkimizda";
import TeklifAl from "../pages/teklif-al/TeklifAl";
import NoPage from "../pages/noPage/NoPage";
import Footer from "../../footer/Footer";
import GoogleReklamlari from "../subpages/dijital-reklamcilik/google-reklamlari/GoogleReklamlari";
import InternetReklamlari from "../subpages/dijital-reklamcilik/internet-reklamlari/InternetReklamlari";
import KartvizitTasarim from "../subpages/logo-ve-kartvizit/kartvizit-tasarim/KartvizitTasarim";
import LogoTasarim from "../subpages/logo-ve-kartvizit/logo-tasarim/LogoTasarim";
import ContactBar from "../../contactBar/ContactBar";
import QualityInfoBar from "../../qualityInfoBar/QualityInfoBar";

function App() {
  return (
    <div className="App">
      <Navbar />

      <Routes>
        <Route path="/">
          <Route index element={<Anasayfa />} />
          {/*------------- Web Tasarım */}
          <Route path="web-tasarim" element={<WebTasarim />} />

          {/*------------- Logo ve Kartvizit */}
          <Route path="logo-ve-kartvizit" element={<LogoVeKartvizit />} />
          <Route
            path="logo-ve-kartvizit/logo-tasarim"
            element={<LogoTasarim />}
          />
          <Route
            path="logo-ve-kartvizit/kartvizit-tasarim"
            element={<KartvizitTasarim />}
          />

          {/*------------- Dijital Reklamcılık */}
          <Route path="dijital-reklamcilik" element={<DijitalReklamcilik />} />
          <Route
            path="dijital-reklamcilik/reklam-vermek"
            element={<GoogleReklamlari />}
          />
          <Route
            path="dijital-reklamcilik/internet-reklami"
            element={<InternetReklamlari />}
          />

          {/*------------- Hakkımızda ve Bize Ulaşın */}
          <Route path="hakkimizda" element={<Hakkimizda />} />
          <Route path="bize-ulasin" element={<BizeUlasin />} />

          {/*------------- Teklif Alın */}
          <Route path="teklif-alin" element={<TeklifAl />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>

      <QualityInfoBar />
      <ContactBar />
      <Footer />
    </div>
  );
}

export default App;
