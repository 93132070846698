import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import styles from "./ContactBar.module.css";

function ContactBar() {
  const [url, setUrl] = useState(
    "https://wa.me/+905319652909?text=Merhaba,%20%C3%BCr%C3%BCn%20ve%20hizmetleriniz%20hakk%C4%B1nda%20bilgi%20almak%20istiyorum."
  );

  setInterval(() => {
    let title = document.title;
    let url = window.location.href;
    title = title.replace(" | Levent Dijital", "");

    setUrl(
      "https://wa.me/+905319652909?text=Merhaba, " +
        title +
        " hakkında bilgi almak istiyorum. " +
        url +
        ""
    );
  }, 1000);

  return (
    <div>
      <div className={styles.container}>
        <a
          onClick="return gtag_report_conversion()"
          href={url}
          target="_blank"
          className={styles.content}
        >
          <Icon color="green" fontSize={32} icon="akar-icons:whatsapp-fill" />
        </a>
      </div>

      <div className={`${styles.container} ${styles.container2}`}>
        <a
          onClick="return gtag_report_conversion()"
          href="tel:+905319652909"
          className={styles.content}
        >
          <Icon color="red" fontSize={32} icon="akar-icons:phone" />
        </a>
      </div>
    </div>
  );
}

export default ContactBar;
