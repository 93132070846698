import { Icon } from "@iconify/react";
import React from "react";
import styles from "./QualityInfoBar.module.css";

function QualityInfoBar() {
  return (
    <div className={styles.container}>
      <ul className={styles.services}>
        <li className={styles.service}>
          <Icon fontSize={38} color="gray" icon="bx:support" />
          <h3>Reklam Uzmanları</h3>
        </li>
        <li className={styles.service}>
          <Icon fontSize={38} color="gray" icon="game-icons:life-support" />
          <h3>Kaliteli Hizmet</h3>
        </li>
        <li className={styles.service}>
          <Icon fontSize={38} color="gray" icon="bi:whatsapp" />
          <h3>7/24 Destek Hattı</h3>
        </li>
        <li className={styles.service}>
          <Icon
            fontSize={38}
            color="gray"
            icon="ant-design:field-time-outlined"
          />
          <h3>Hızlı İletişim</h3>
        </li>
      </ul>

    </div>
  );
}

export default QualityInfoBar;
