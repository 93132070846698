import React from "react";
import styles from "./PageHeader.module.css";

function PageHeader(props) {
  return (
    <div
      style={{
        backgroundImage: `url(
          "${props.image}"
        )`,
        
      }}
      className={styles.titleArea}
    >
      <h1>{props.title}</h1>
    </div>
  );
}

export default PageHeader;
