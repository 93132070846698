import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./NoPage.module.css";

function NoPage({ props }) {
  useEffect(() => {
    document.title = "Böyle bir sayfa bulunmuyor | Levent Dijital";
    return () => {};
  }, []);

  return (
    <div className={styles.container}>
      <p className={styles.no_page_title}>Böyle bir sayfa bulunmuyor !</p>
      <div className={styles.no_page_link_container}>
        <Link className={styles.no_page_link} to={"/"}>
          Anasayfa
        </Link>
        <Link className={styles.no_page_link} to={"/bize-ulasin"}>
          Bize Ulaşın
        </Link>
      </div>
    </div>
  );
}

export default NoPage;
