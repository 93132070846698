import React from "react";
import { useEffect } from "react";
import PageHeader from "../../../pageHeader/PageHeader";
import headerImage from "../../../../static/backgrounds/bg_6.webp";
import styles from "./LogoVeKartvizit.module.css";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

function LogoVeKartvizit() {
  useEffect(() => {
    document.title = "Logo ve Kartvizit | Levent Dijital";
    return () => {};
  }, []);

  let renderQuestions = () => (
    <div className={styles.container_1}>
      <div className={styles.container_question}>
        <h2>
          <strong>Tasarım'ın Önemi</strong>
        </h2>
        <p>
          Markanızın logosu ve renkleri, müşterilerinizin markanıza bakışını
          etkiler. <br />
          <br />
          İyi bir tasarım, markanızı müşterilere daha iyi pazarlamanızı, akılda
          kalıcı bir marka oluşturmanızı sağlar. <br />
          <br /> "Bazı markalar vardır, ürünlerini hiç kullanmasak bile
          dostlarımıza tavsiye ettiğimiz..."
          <br />
        </p>
      </div>
      {renderRedirectBar()}

      <div className={styles.container_question}>
        <h2>
          <strong>Biz Ne Yapıyoruz ?</strong>
        </h2>
        <p>
          Levent Dijital olarak markanızı en iyi yansıtacak logo ve kartvizit
          tasarımlarını sağlarız. <br /> En yüksek verimi almanız için size
          "ezber bozan" bilgiler veriyoruz. <br />
          <br /> İstediğiniz tasarım hizmetlerini size sunar, bunları efektif
          kullanmanız'da yardımcı oluruz. <br />
          <br />
          <Link
            className={styles.reach_us_link}
            to={"/logo-ve-kartvizit/logo-tasarim"}
          >
            Logo tasarım çalışmalarımıza göz atın
            <Icon
              fontSize={12}
              style={{ marginLeft: "5px" }}
              color="#9a0000"
              className={styles.forwardIcon}
              icon="akar-icons:arrow-forward"
            />
            .
          </Link>
          <br />
          <br />
          <Link
            className={styles.reach_us_link}
            to={"/logo-ve-kartvizit/kartvizit-tasarim"}
          >
            Kartvizit tasarım çalışmalarımıza göz atın
            <Icon
              fontSize={12}
              style={{ marginLeft: "5px" }}
              color="#9a0000"
              className={styles.forwardIcon}
              icon="akar-icons:arrow-forward"
            />
            .
          </Link>
        </p>
      </div>
    </div>
  );

  let renderContactBar = () => (
    <div className={styles.contactBar_container}>
      <ul>
        <li>
          <a href="#footer">İletişim</a>
        </li>
        <li>
          <Link to="/teklif-alin">Fiyat Alın</Link>
        </li>
      </ul>
    </div>
  );

  let renderRedirectBar = () => (
    <div className={styles.contactBar_container}>
      <ul>
        <li>
          <Link to="/logo-ve-kartvizit/logo-tasarim">Logo Tasarım</Link>
        </li>
        <li>
          <Link to="/logo-ve-kartvizit/kartvizit-tasarim">
            Kartvizit Tasarım
          </Link>
        </li>
      </ul>
    </div>
  );

  return (
    <div className={styles.container}>
      <PageHeader title="Logo ve Kartvizit Tasarımı" image={headerImage} />

      {renderContactBar()}
      {renderQuestions()}
    </div>
  );
}

export default LogoVeKartvizit;
