import React from "react";
import { useEffect } from "react";
import PageHeader from "../../../pageHeader/PageHeader";
import headerImage from "../../../../static/backgrounds/bg_3.webp";
import styles from "./DijitalReklamcilik.module.css";
import { Link } from "react-router-dom";

function DijitalReklamcilik() {
  useEffect(() => {
    document.title = "Dijital Reklamcılık | Levent Dijital";
    return () => {};
  }, []);

  let renderQuestions = () => (
    <div className={styles.container_1}>
      <div className={styles.container_question}>
        <h2>
          <strong>Dijital Reklam Nedir ?</strong>
        </h2>
        <p>
          Her gün, binlerce insan sunduğunuz ürün ve hizmetleri arıyor. <br />
          <br />
          İnternette reklamlarınızı yayınlayarak müşterilere ulaşmanız mümkün.
          <br />
          <br /> Dijital Reklamlar ile reklamlarınız internette yayınlanır,
          hizmet ve ürünleriniz müşterilerinize ulaşır.
          <br />
        </p>
      </div>
      {renderRedirectBar()}

      <div className={styles.container_question}>
        <h2>
          <strong>Biz Ne Yapıyoruz ?</strong>
        </h2>
        <p>
          Levent Dijital olarak reklamlarınızın yayınlanacağı en uygun mecraları
          seçeriz. <br />
          <br /> Reklamlarınızı oluşturur, hedef kitlenizi belirler ve anahtar
          kelime optimizasyonu yaparız. <br />
          <br />
          Reklamlarınızı, Müşterilerinizi ve Analytics Verilerinizi düzenli
          olarak takip ederiz ve satışlarınızı arttırmak için çalışırız.
          <br /> <br />
          <Link className={styles.reach_us_link} to={"/bize-ulasin"}>
            İletişim için tıklayın.
          </Link>
          <br />
          <br />
          <Link className={styles.reach_us_link} to={"/teklif-alin"}>
            Fiyat teklifi almak için tıklayın.
          </Link>
        </p>
      </div>

      <div className={styles.container_question}>
        <h2>
          <strong>Reklamlar Nerede Yayınlanır ?</strong>
        </h2>
        <p>
          Hizmet ve ürünlerinizin reklamları Google Arama Sonuçları & Youtube &
          Internet Siteleri ve Sosyal Medya'da yayınlanabilir. İhtiyaçlarınıza
          göre en çok satış yapacağınız reklam alanlarını belirleriz ve
          bütçenize uygun şekilde reklamlarınızı yayınlarız. <br /> <br />
          Her müşteri, hizmet ve ürünü hemen almayabilir. Müşterileriniz ile
          iletişim halinde kalıp, müşterinin ürünler ile daha fazla vakit
          geçirmesini sağlıyoruz.
          <br />
          <br />
          Müşterilerinizin geçmişte bakmış olduğu ürünleri tekrar'dan müşterinin
          karşısına çıkartır ve satışlarınızı arttırırız, çapraz satış yapmanızı
          sağlarız. <br /> <br />
          Reklamlarınızın nasıl yayınlanacağı ve müşteriler ile nasıl etkileşime
          geçeceğiniz hizmet verdiğiniz sektöre özeldir. Hizmetlerimiz hakkında
          detaylı bilgi almak için <Link to={"/bize-ulasin"}>bize ulaşın</Link>.
        </p>
      </div>
    </div>
  );

  let renderContactBar = () => (
    <div className={styles.contactBar_container}>
      <ul>
        <li>
          <a href="#footer">İletişim</a>
        </li>
        <li>
          <Link to="/teklif-alin">Fiyat Alın</Link>
        </li>
      </ul>
    </div>
  );

  let renderRedirectBar = () => (
    <div className={styles.contactBar_container}>
      <ul>
        <li>
          <Link to="/dijital-reklamcilik/reklam-vermek">Google Reklamları</Link>
        </li>
        <li>
          <Link to="/dijital-reklamcilik/internet-reklami">
            İnternet Reklamları
          </Link>
        </li>
      </ul>
    </div>
  );

  return (
    <div className={styles.container}>
      <PageHeader
        title="Dönüşüm Odaklı Dijital Reklamcılık"
        image={headerImage}
      />

      {renderContactBar()}
      {renderQuestions()}
    </div>
  );
}

export default DijitalReklamcilik;
