import React from "react";
import { useEffect } from "react";
import PageHeader from "../../../pageHeader/PageHeader";
import headerImage from "../../../../static/backgrounds/bg_7.webp";
import styles from "./TeklifAl.module.css";
import { newContactForm } from "../../../../services/firebase/realtimeDatabase";
import { useState } from "react";

function TeklifAl() {
  const [submitFormSuccess, setSubmitFormSuccess] = useState(false);

  useEffect(() => {
    document.title = "Fiyat Teklifi Alın | Levent Dijital";
    return () => {};
  }, []);

  let submitForm = (e) => {
    e.preventDefault();
    if (submitFormSuccess === false) {
      newContactForm(
        e.target.name.value,
        e.target.phone.value,
        e.target.subject.value,
        e.target.message.value
      );
    }
    setSubmitFormSuccess(true);
  };

  return (
    <div>
      <PageHeader title="Bizden Fiyat Alın" image={headerImage} />

      <div className={styles.wrapper_form}>
        <div className={styles.title}>Sizi Arayalım</div>

        <form className={styles.contactForm} onSubmit={submitForm} action="#">
          <input type="text" name="name" placeholder="(*) Ad Soyad" required />
          <input
            type="tel"
            name="phone"
            required
            placeholder="(*) Telefon Numarası"
          />
          <input
            type="text"
            name="subject"
            required
            placeholder="(*) Teklif Almak İstediğiniz Konu "
          />
          <input type="" name="message" placeholder="Mesajınız " />
          <button type="submit">Gönder</button>
          {submitFormSuccess === true ? (
            <p className={styles.submitFormSuccess}>
              Form Başarıyla Gönderildi
            </p>
          ) : undefined}
        </form>
      </div>
    </div>
  );
}

export default TeklifAl;
